var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Feedback',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Use our custom progress component for displaying simple or complex progress bars, featuring support for horizontally stacked bars, animated backgrounds, and text labels. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('labels')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('custom-label')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('width')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('height')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('solid-background-color')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('striped-backgrounds')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('animated-backgrounds')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('multiple-bars')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }